.App {
}

.App-logo {
    width: 60px;
    height: 60px;

    margin-right: 10px;
}

.App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
}

.App-link {
    color: #61dafb;
}

@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

.custom-shape-divider-bottom-1603446802 {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    overflow: hidden;
    line-height: 0;
    z-index: -1;
}

.custom-shape-divider-bottom-1603446802 svg {
    position: relative;
    display: block;
    width: calc(100% + 1.3px);
    height: 500px;
    transform: rotateY(180deg);
}

.custom-shape-divider-bottom-1603446802 .shape-fill {
    fill: #ffffff;
}

.dots {
    list-style: none;
    text-align: center;
    padding: 0;
    margin-bottom: 40px;
}

/* .dot {
    display: inline-block;
    position: relative;
    display: inline-block;
    width: 20px;
    height: 20px;
    margin: 0 5px;
    padding: 0;
    cursor: pointer;
}

.dots .dot button {
    font-size: 0;
    line-height: 0;
    display: block;
    width: 20px;
    height: 20px;
    padding: 5px;
    cursor: pointer;
    color: transparent;
    border: 0;
    outline: none;
    background: transparent;
}

.dots .dot button::before {
    font-size: 20px;
    line-height: 15px;
    position: absolute;
    top: 0;
    left: 0;
    width: 20px;
    height: 20px;
    content: "•";
    text-align: center;
    /* opacity: 0.25; */
