body {
    margin: 0;
    font-family: "Paytone One", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background: rgb(33, 195, 225);
    background: linear-gradient(
        60deg,
        rgba(33, 195, 225, 1) 0%,
        rgba(40, 227, 238, 1) 100%
    );
    min-height: 100vh;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
        monospace;
}
