.footer {
    display: flex;
    flex-direction: column;
    font-family: "Lato";
    font-size: 14px;
    font-weight: 700;
    color: white;
}

.footer > * {
    margin-bottom: 15px;
}
